<script setup>
import SideSlider from "~/components/templates/SideSlider.vue";
import {useQuotationStore} from "~/store/quotationStore.js";
import {useStartupStore} from "~/store/sturtupStore.js";
import InputsGroup from "~/components/atoms/Inputs/InputsGroup.vue";
import FormInput from "~/components/atoms/FormInput.vue";
import Form from "~/components/atoms/Form.vue";
import Text from "~/components/atoms/Text.vue";
import Button from "~/components/atoms/Button.vue";
import LeftIcon from "~/components/icons/LeftIcon.vue";
import CircleCheckboxIcon from "~/components/icons/CircleCheckboxIcon.vue";
import CartProductItem from "~/components/molecules/CartProductItem.vue";
import {useCustomFetch} from '~/composables/customFetch.js';

const store = useQuotationStore();
const startupStore = useStartupStore();

const addComment = ref(false);
const addProductMode = ref(false);
const search = ref('');
const products = ref([]);
const container = ref(null)
const success = ref(false)
const noProductsError = ref(false)

watch(search, () => {
  if (search.value.length > 3) {
    searchProduct()
  }
})

watch(addProductMode, () => {
  nextTick(() => {
    container.value.scrollTop = 0
  })
})

const searchProduct = () => {
  useCustomFetch('product/search/' + search.value, {
    query: {
      quotation: 1
    }
  }).then(({data}) => {
    products.value = data.value.products
  })
}

const addProduct = (product) => {
  noProductsError.value = false
  store.addProduct(product.image, product.name, product.sku, product.unit, product.box_per_unit)
  addProductMode.value = false
}

const send = (formData) => {
  if (!store.products.length) {
    noProductsError.value = true
    return
  }

  formData.append('products', JSON.stringify(store.products))

  useCustomFetch('quotation', {
    method: 'POST',
    body: formData
  }, true).then((data) => {
    success.value = true
    store.clearProducts()

    if (typeof (dataLayer) != "undefined" && dataLayer !== null) {
      dataLayer?.push({
        ecommerce: null
      });

      dataLayer?.push({
        'event': 'quotationSent',
        'ecommerce': {
          transaction_id: data.reference,
          revenue: data.total,
          value: data.total,
          tax: Math.round(data.total - (data.total * 0.8)).toFixed(2),
          currency: 'SEK',
          total_price: data.total,
          items: data.products
        }
      });
    }
  })
}
</script>

<template>
  <SideSlider @set-container-ref="container=$event" @close="success=false; store.closeQuotation();" position="right"
              width="480px"
              :title="$t('quotation.company-quotation')"
              :active="store.quotationIsActive">

    <div class="g-16">
      <div v-if="!success" class="row flex-start"
           style="overflow-x: hidden; max-height: 100%; max-width: 480px;">
        <div class="slide g-24" :class="{active:addProductMode}">
          <Form submit-padding="0 24px" class="form" @submit="send" ref="form">

            <template #default>
              <div class="g-16">
                <div class="g-32">
                  <div class="quotation-container no-flex g-16">
                    <InputsGroup>
                      <FormInput :with-validation-approve="true" type="select" v-model="store.form.type"
                                 :label="$t('form.fields.type')"
                                 name="client_data[type]"
                                 :options="[{key:'private',value:$t('form.fields.private')},{key:'company',value:$t('form.fields.company')}]"/>
                      <FormInput :with-validation-approve="true" :validations="['required']" type="text"
                                 :label="$t('form.fields.name')"
                                 name="client_data[name]"
                                 v-model="store.form.name"/>
                    </InputsGroup>
                    <InputsGroup v-if="store.form.type === 'company'">
                      <FormInput :with-validation-approve="true" :validations="['required']" type="text"
                                 :label="$t('form.fields.company_name')"
                                 name="client_data[company_name]"
                                 v-model="store.form.companyName"/>
                      <FormInput :with-validation-approve="true" :validations="['required','min:10']" type="text"
                                 :label="$t('form.fields.company_number')"
                                 name="client_data[company_number]"
                                 v-model="store.form.companyNumber"/>
                    </InputsGroup>
                    <InputsGroup>
                      <FormInput :with-validation-approve="true" :validations="['required']" type="text"
                                 :label="$t('form.fields.phone')"
                                 name="client_data[phone]"
                                 v-model="store.form.phone"/>
                      <FormInput :with-validation-approve="true" :validations="['required','email']" type="email"
                                 :label="$t('form.fields.email')"
                                 name="client_data[email]"
                                 v-model="store.form.email"/>

                    </InputsGroup>
                    <FormInput :with-validation-approve="true" :validations="['required']" type="text"
                               :label="$t('form.fields.delivery_address')"
                               name="client_data[delivery_address]"
                               v-model="store.form.address"/>
                    <InputsGroup>
                      <FormInput :with-validation-approve="true" :validations="['required']" type="text"
                                 :label="$t('form.fields.zip')"
                                 name="client_data[zip]" v-model="store.form.zip"/>
                      <FormInput type="select" v-model="store.form.country" :label="$t('form.fields.country')"
                                 name="client_data[country]"
                                 :options="startupStore.countries.map(country => ({
                        key: country.code,
                        value: country.name,
                        prefix: `<img style='width: 20px;' src='${country.flag}'/>`
                     }))"/>
                    </InputsGroup>


                    <Text v-if="!addComment" class="clickable" tag="strong" @click="addComment=true">{{
                        $t('base.add_comment')
                      }}
                    </Text>

                    <FormInput v-if="addComment" type="textarea" v-model="store.form.comment"
                               :label="$t('form.fields.comment')"
                               name="comment"/>
                  </div>

                  <div class="quotation-container no-flex g-16">
                    <CartProductItem :link="'/shop/'+product.url" :name="product.name" :quantity="product.quantity"
                                     :sku="product.sku" :image="product.image" :unit="product.unit"
                                     :key="product.sku"
                                     :unit_per_box="product.unit_per_box" :is_sample="false"
                                     @minus="product.quantity > 1 ? product.quantity-- : false"
                                     @remove="store.removeProduct(product.sku)"
                                     @change="product.quantity=$event.target.value"
                                     @plus="product.quantity++"
                                     v-for="product in store.products"/>
                  </div>
                </div>
                <div class="quotation-container g-16">
                  <Text tag="strong" style="margin-top: -16px; margin-bottom: 24px;" class="clickable"
                        @click="addProductMode=true">+ {{
                      $t('product.add_product')
                    }}
                  </Text>

                  <Text class="info-text">{{ $t('quotation.text') }}</Text>
                  <Text class="red" v-if="!useQuotationStore().products.length && noProductsError">Vänligen lägg till minst en product</Text>
                </div>
              </div>
            </template>
            <template #submit>
              {{ $t('quotation.send') }}
            </template>
          </Form>
        </div>
        <div class="slide no-flex g-24">
          <div class="quotation-container">
            <div class="row full-width g-32">
              <div class="no-flex">
                <LeftIcon class="clickable" @click="addProductMode=false"/>
              </div>
              <FormInput type="text" :label="$t('product.quick_search_placeholder')" v-model="search"/>
            </div>
          </div>

          <div class="products" v-if="addProductMode">
            <div class="quotation-container">
              <div class="product row full-width stretch g-24" v-for="product in products">
                <div class="image">
                  <img :src="product.image" alt="product"/>
                </div>
                <div class="full-width space-between">
                  <div class="full-width row space-between flex-start">
                    <nuxt-link :to="'/shop/'+product.url">
                      <Text>{{ product.name }}</Text>
                    </nuxt-link>
                  </div>
                  <div class="space-between row full-width">
                    <Button @click="addProduct(product)" type="black" class="short" text="+ Add"/>
                    <Text class="sku">{{ product.sku }}</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="success flex-center g-16 quotation-container">
        <CircleCheckboxIcon/>

        <Text tag="strong">{{ $t('quotation.success.thank-you') }}</Text>

        <Text text-alignment="center">{{ $t('quotation.success.will_call_you_back') }}</Text>
        <Text text-alignment="center">{{ $t('quotation.success.disclaimer') }}</Text>
      </div>
    </div>
  </SideSlider>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.slide {
  min-width: 100%;
  transition: 0.3s all ease;
  padding-bottom: 30px;

  &.active {
    margin-left: -100%;
  }
}

.quotation-container {
  padding: 0 24px;
}

.products {
  background: $light-background;

  .product {
    padding: 12px 0;
  }
}

.image {
  max-width: 68px;
}

.info-text {
  color: $light-black;
  font-size: 12px;
}

.success {
  svg {
    stroke: $green;
    width: 64px;
    height: 64px;
  }

  strong {
    font-size: 24px;
  }
}

.form:deep(button) {
  font-size: 14px;
}
</style>
